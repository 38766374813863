@import url(~@fullcalendar/core/main.css);
@import url(~@fullcalendar/daygrid/main.css);
@import url(~@fullcalendar/timegrid/main.css);
body {
  height: 100vh;
  background-color: #fafafa;
  /** IE Custom scroll bar*/
  scrollbar-width: 9px !important;
  scrollbar-base-color: #d4d4d4;
  scrollbar-face-color: #d4d4d4;
  scrollbar-3dlight-color: #f7f7f7;
  scrollbar-highlight-color: #bbbbbb;
  scrollbar-track-color: #fcfcfc;
  scrollbar-arrow-color: #575757;
  scrollbar-shadow-color: #f0f0f0;
  scrollbar-dark-shadow-color: #e4e4e4; }

#root {
  height: 100%;
  background-color: #fafafa; }

.logotype-sip {
  width: 90px;
  height: auto;
  margin-right: 2rem; }

.bgCompany {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: auto; }

.logoheader-sip {
  width: 100%;
  height: 100%;
  padding: 0px !important; }

.dialogContent-padding-custom {
  padding: 0px !important; }

/** SCROLL STYLE SECTION */
/* width */
::-webkit-scrollbar {
  height: 9px;
  width: 9px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #e7e7e7; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 5px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa; }

.search-folio {
  width: 95%;
  height: 31px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(226, 226, 226, 0.611); }

.div-search-folio {
  padding-top: 5%;
  display: block;
  margin: auto;
  width: 80%;
  padding-bottom: 10%; }

#DropzoneArea-dropZone {
  background-color: #fafafa !important;
  border-radius: 8px !important;
  border-style: dotted !important;
  border-width: .12em !important;
  min-height: 10px !important;
  height: 95px !important;
  max-height: 95px !important;
  min-height: 10px !important; }

.div-register {
  padding-top: 5%;
  display: block;
  margin: auto;
  width: 80%;
  padding-bottom: 3%; }

.registerContainer {
  margin: 0px auto 0px auto;
  padding: 30px;
  color: #929292 !important;
  font-family: 'Arsenal', sans-serif;
  width: 1100px; }

.authenticated-container {
  display: grid;
  background-color: #fafafa; }
  @media (min-width: 769px) {
    .authenticated-container {
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto; }
      .authenticated-container #application-bar {
        grid-column: 2 / 3;
        grid-row: 1 / 2; }
      .authenticated-container #lateral-menu {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        width: 210px; }
      .authenticated-container main {
        grid-column: 2 / 3;
        grid-row: 2 / 3; } }

.footer-container {
  text-align: center;
  margin-top: .4rem; }
