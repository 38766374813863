.dropzone-container {
    background-color: #fafafa !important;
    border-radius: 8px !important;
    border-style: dotted !important;
    border-width: .12em !important;
    min-height: 10px !important;
    height: 95px !important;
    max-height: 95px !important;
    min-height: 10px !important;
}

.DropzoneArea-dropZone{
    background-color: #fafafa !important;
    border-radius: 8px;
    border-style: dotted;
    border-width: .12em !important;
    min-height: 10px !important;
    height: 95px !important;
    max-height: 95px !important;
}

.dropzone-text {
    font-family: 'Arsenal', "sans-serif", "Latin";
    font-size: 1.3em !important;
    color: rgba(0, 0, 0, 0.38);
    position: absolute; 
    top: 50px; 
    left: 25%;
    text-align: center;
    padding: 0;
}

.files-container {
    background-color: #fafafa !important;
    border-radius: 8px !important;
    border-style: dotted !important;
    border-width: .12em !important;
    min-height: 10px !important;
    height: 95px !important;
    max-height: 95px !important;
}